import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/credits": [10],
		"/home": [11],
		"/profiles": [12],
		"/profiles/new": [17],
		"/profiles/[idprofilo]": [13],
		"/profiles/[idprofilo]/certs": [14],
		"/profiles/[idprofilo]/edit": [15],
		"/profiles/[idprofilo]/stats": [16],
		"/sentry-example": [18],
		"/settings": [19],
		"/test": [20],
		"/[stage]/games": [5,[2]],
		"/[stage]/games/[id]": [6,[2]],
		"/[stage]/old/letters": [7,[2]],
		"/[stage]/old/phrases": [8,[2]],
		"/[stage]/old/tutorial": [9,[2]],
		"/[stage]/[mode]": [4,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';